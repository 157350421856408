import Link from 'next/link'
import ResponsiveImage from '@/components/ResponsiveImage'
import IconFacebook from '@/svgs/facebook.svg'
import IconInstagram from '@/svgs/instagram.svg'
import IconPinterest from '@/svgs/pinterest.svg'
import IconTwitter from '@/svgs/twitter.svg'
import IconVimeo from '@/svgs/vimeo.svg'
import IconYoutube from '@/svgs/youtube.svg'
import FooterMenuItems from '../FooterMenuItems'

const logoImgLoader = ({ src }) => {
  return `${src}?w=500`
}

const Icons = {
  facebook: <IconFacebook />,
  instagram: <IconInstagram />,
  pinterest: <IconPinterest />,
  twitter: <IconTwitter />,
  vimeo: <IconVimeo />,
  youtube: <IconYoutube />,
};

const socialLinkItem = (item, classes) => (
  <li
    key={item._key}
    className={[classes.socialLinkItem, classes[item.platform]].join(' ')}
  >
    <Link prefetch={false} href={item.socialLink ? item.socialLink : '/'}>
      {Icons[item.platform] || ''}
    </Link>
  </li>
);

const FooterNavigation = ({ props, classes }) => {
  const { footerNavigationList } = props;
  const { socialLinks } = props;
  const { logo } = props;

  return (
    <div className={[classes.footerSection, classes.footerNavigation].join(' ')}>
      <div className={classes.footerLogoAndSocials}>
        <div className={classes.footerLogo}>
          <Link prefetch={false} href="/">
            <ResponsiveImage
              loader={logoImgLoader}
              src={logo.asset.url}
              alt={logo.asset.alt || 'Logo'}
            />
          </Link>
        </div>
        <ul className={classes.footerSocials}>
          {socialLinks.map((item) => socialLinkItem(item, classes))}
        </ul>
      </div>
      <ul className={classes.footerNavigationList}>
        {footerNavigationList.map((item) => (
          <FooterMenuItems item={item} classes={classes} key={item._key} />
        ))}
      </ul>
    </div>
  );
};

export default FooterNavigation;
