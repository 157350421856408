import classes from './ProductFooter.module.scss'

import ProductEmailSignupBlock from '@/components/Sections/ProductEmailSignupBlock'
import FooterNavigation from './FooterNavigation'
import FooterBottom from './FooterBottom'
import FooterProps from './FooterProps'

const ProductFooter = ({ content }) => {

  if (!content) {
    return null;
  }

  return (
    <div className={classes.footer}>
      <div className="container">
        <ProductEmailSignupBlock props={content} />
        <FooterNavigation props={content} classes={classes} />
        <FooterBottom props={content} classes={classes} />
      </div>
    </div>
  )
}

export default ProductFooter
